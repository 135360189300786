<template>
  <div class="change-password">
    <div class="progress-bar">
      <img src="@/assets/images/jdt.png" alt="" />
      <div
        class="circle"
        style="left: -29px"
        :class="index >= 0 ? 'state' : ''"
      >
        <i></i>
        <p class="num">1</p>
        <p class="text">验证身份</p>
      </div>
      <div
        class="bar"
        style="left: 36px"
        :style="index >= 1 ? 'background: #00cc88' : ''"
      ></div>
      <div
        class="circle"
        style="left: 150px"
        :class="index >= 1 ? 'state' : ''"
      >
        <i></i>
        <p class="num">2</p>
        <p class="text">重置登录密码</p>
      </div>
      <div
        class="bar"
        style="left: 216px"
        :style="index >= 2 ? 'background: #00cc88' : ''"
      ></div>
      <div
        class="circle"
        style="left: 329px"
        :class="index >= 2 ? 'state' : ''"
      >
        <i></i>
        <p class="num">3</p>
        <p class="text">修改成功</p>
      </div>
    </div>
    <div class="message">
      <div v-if="index === 0">
        <p>原登录密码：</p>
        <input
          type="password"
          v-model="OriginalPassword"
          @keyup.enter="Submit"
        />
        <!-- <svg-icon icon-class="ic-x"></svg-icon> -->
      </div>

      <div style="margin-bottom: 30px" v-if="index === 1">
        <p>新密码：　　</p>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="NewPassword">
            <el-input
              :type="NewPasswordShow ? 'text' : 'password'"
              v-model="ruleForm.NewPassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- <input
          :type="NewPasswordShow ? 'text' : 'password'"
          v-model="NewPassword"
        /> -->
        <svg-icon
          v-show="ruleForm.NewPassword"
          @click="NewPasswordShow = !NewPasswordShow"
          :icon-class="NewPasswordShow ? 'ic-eyes' : 'ic-closeEyes'"
        ></svg-icon>
      </div>
      <div v-if="index === 1">
        <p>确认新密码：</p>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="NewPassword2">
            <el-input
              :type="NewPasswordShow2 ? 'text' : 'password'"
              v-model="ruleForm.NewPassword2"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- <input
          :type="NewPasswordShow2 ? 'text' : 'password'"
          v-model="NewPassword2"
        /> -->
        <svg-icon
          v-show="ruleForm.NewPassword2"
          @click="NewPasswordShow2 = !NewPasswordShow2"
          :icon-class="NewPasswordShow2 ? 'ic-eyes' : 'ic-closeEyes'"
        ></svg-icon>
      </div>

      <div
        v-if="index === 2"
        style="flex-direction: column; align-items: center"
      >
        <img src="@/assets/images/prosperity.png" alt="" />
        <p style="color: #666666">登录密码修改成功</p>
      </div>
    </div>
    <button
      :style="
        OriginalPassword || ruleForm.NewPassword || ruleForm.NewPassword2
          ? 'background:#00aeeb;cursor: pointer'
          : ''
      "
      @click="Submit"
      v-if="index != 2"
    >
      提交验证
    </button>
    <!-- <a :href="$URL" v-if="index === 2" class="log-in-again">重新登录</a> -->
    <a @click="reLogin" v-if="index === 2" class="log-in-again">重新登录</a>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "change-password",

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.NewPassword2 !== "") {
          this.$refs.ruleForm.validateField("NewPassword2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.NewPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      index: 0,
      OriginalPassword: "", //原密码input
      ruleForm: {
        NewPassword: "", //新密码
        NewPassword2: "", //确认新密码
      },
      rules: {
        NewPassword: [{ validator: validatePass, trigger: "blur" }],
        NewPassword2: [{ validator: validatePass2, trigger: "blur" }],
      },
      NewPasswordShow: false,
      NewPasswordShow2: false,
    };
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log(this.user);
  },
  methods: {
    Submit() {
      this.index === 0 && this.matchePassword();
      this.index === 1 && this.changePassword();
    },

    async matchePassword() {
      //验证密码
      if (this.OriginalPassword) {
        const data = await this.$API.personalUser.matchePassword({
          object: {
            actualName: this.user.userEntity.actualName,
            id: this.user.id,
            name: this.user.userEntity.name,
            password: this.OriginalPassword,
            telNum: this.user.userEntity.telNum,
            username: this.user.userEntity.username,
          },
        });
        if (data.data.data) {
          this.index++;
        } else {
          this.$message({
            message: "密码错误",
            type: "error",
            offset: "450",
          });
        }
      }
    },

    changePassword() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          // console.log(1);
          const data = await this.$API.personalUser
            .updateUser({
              object: {
                id: this.user.id,
                userDetailEntity: {
                  // frontId: "",
                  id: this.user.id,
                  // idNumber: "",
                  profilePicture: this.user.userDetailEntity.profilePicture, //头像
                  // reverseId: "",
                  // userId: "",
                },
                userEntity: {
                  actualName: "",
                  id: this.user.id,
                  password: this.ruleForm.NewPassword2,
                  name: this.user.userEntity.name, //名称
                  telNum: this.user.userEntity.telNum,
                  username: this.user.userEntity.username,
                },
              },
            })
            .then((data) => {
              this.index++;
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("更改失败");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    reLogin() {
      Cookies.remove("token_personal");
      window.location.replace(this.$URL);
      // this.$message({
      //   message: "5s后跳转登录界面",
      //   type: "success",
      //   offset: "450",
      // });
      // Cookies.remove("token_personal");
    },
  },
};
</script>

<style lang="scss" scoped>
.change-password {
  padding: 40px 300px;
  .progress-bar {
    position: relative;
    margin-bottom: 66px;
    .circle {
      position: absolute;
      width: 100px;
      top: 4px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .num {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        line-height: 34px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        // background: #00cc88;
        position: relative;
      }
      .text {
        margin-top: 10px;
        font-size: 14px;
        color: #999999;
      }
      i {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        position: absolute;
        top: -4px;
        left: 29px;
        // background: #d8f7ed;
        z-index: 0;
      }
    }
    .bar {
      width: 147px;
      height: 6px;
      // background: #00cc88;
      position: absolute;
      top: 18px;
      z-index: 10;
    }
    .state {
      .num {
        background: #00cc88;
      }
      .text {
        color: #00cc88;
      }
      i {
        background: #d8f7ed;
      }
    }
  }
  .message {
    font-size: 14px;
    color: #333333;
    div {
      display: flex;
      align-items: center;
      position: relative;
      .el-form-item {
        margin-bottom: 0;
      }
      ::v-deep .el-input__inner {
        width: 306px;
        border-radius: 0;
        padding-left: 12px;
        padding-right: 40px;
      }

      input {
        width: 306px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        padding-left: 12px;
        padding-right: 40px;
      }
      input:focus {
        border: 1px solid #00aeeb;
        outline: none;
      }
      .svg-icon {
        position: absolute;
        right: 20px;
        cursor: pointer;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
      }
      img {
        margin-bottom: 8px;
      }
    }
  }
  button {
    width: 400px;
    height: 42px;
    background: #dddddd;
    font-size: 14px;
    color: #ffffff;
    border: none;
    margin-top: 40px;
  }
  .log-in-again {
    display: inline-block;
    width: 340px;
    height: 42px;
    background: #00aeeb;
    border-radius: 2px;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    margin-top: 30px;
    position: relative;
    left: 50%;
    margin-left: -170px;
    cursor: pointer;
  }
}
</style>